import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import sliderLogo from '../../Assets/Images/logo-slider.png';
import undpLogo from '../../Assets/Images/undp1.webp';
import EBRD from '../../Assets/Images/EBRD.webp';
import EBRDff from '../../Assets/Images/EBRD.png';
import UNFCCC from '../../Assets/Images/UNFCCC.webp';
import UNFCCCff from '../../Assets/Images/UNFCCC.png';
import IETA from '../../Assets/Images/IETA.webp';
import IETAff from '../../Assets/Images/IETA.png';
import ESA from '../../Assets/Images/ESA.webp';
import ESAff from '../../Assets/Images/ESA.png';
import WBANK from '../../Assets/Images/WBANK.webp';
import WBANKff from '../../Assets/Images/WBANK.png';
import forestfall from '../../Assets/Images/forestnew.png';
import resources from '../../Assets/Images/resources.webp';
import resourcesfall from '../../Assets/Images/resources.png';
import LayoutFooter from '../../Components/Footer/layout.footer';
import { ImgWithFallback } from '@undp/carbon-library';
import './homepage.scss';
import { Search, ListTask, GraphUpArrow, Flower1 } from 'react-bootstrap-icons';
const Homepage = () => {
  const { i18n, t } = useTranslation(['common', 'homepage']);
  const navigate = useNavigate();
  const [Visible, setVisible] = useState(true);

  const controlDownArrow = () => {
    if (window.scrollY > 150) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const handleClickScroll = () => {
    const element = document.getElementById('scrollhome');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (localStorage.getItem('i18nextLng')!.length > 2) {
      i18next.changeLanguage('en');
    }
    window.addEventListener('scroll', controlDownArrow);
    return () => {
      window.removeEventListener('scroll', controlDownArrow);
    };
  }, []);
  return (
    <div className="homepage-container">
      <Row>
        <Col md={24} lg={24} flex="auto">
          <div className="homepage-img-container image-container">
            <Row>
              <Col md={18} lg={21} xs={17} flex="auto">
                <div className="homepage-header-container">
                  <div className="logo">
                    <img src={sliderLogo} alt="slider-logo" />
                  </div>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <div className="title">{'CARBON'}</div>
                      <div className="title-sub">{'REGISTRY'}</div>
                    </div>
                    <div className="country-name">
                      {process.env.REACT_APP_COUNTRY_NAME || 'CountryX'}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3} xs={7} flex="auto">
                <div className="homepage-button-container">
                  <div className="button">
                    <Button type="primary" onClick={() => navigate('/login')}>
                      SIGN IN
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="text-ctn">
                <span>
                  {'Signet'} {'carbon'} <br />
                  {'registry'}
                </span>
                <div className="subhome">{'Demo Website'}</div>
              </div>
            </Row>
            <Row>
              {Visible && (
                <nav className={'arrows'}>
                  <svg onClick={handleClickScroll}>
                    <path className="a1" d="M0 0 L30 32 L60 0"></path>
                    <path className="a2" d="M0 20 L30 52 L60 20"></path>
                    <path className="a3" d="M0 40 L30 72 L60 40"></path>
                  </svg>
                </nav>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col md={24} lg={24} flex="auto">
          <div className="homepage-content-containerwhite">
            <div id="scrollhome" className="title">
              {"SIGNET is Sage Intel's groundbreaking Blockchain-Based Verification platform."}
            </div>
            <div className="homepagebody">
              <div className="homepagebody_aboutusline1">
                {
                  'It is engineered to serve a diverse range of stakeholders, from governments and businesses to individual asset owners. The platform sets a new industry benchmark for ensuring the authenticity, reliability, and traceability of registered assets.'
                }
              </div>
              <div className="homepagebody_subtitle">{t('homepage:Keyfeatures')}</div>

              <div className="aboutus_cards-container">
                <Row gutter={[5, 5]} className="aboutus_card-row">
                  <Col xxl={6} xl={6} md={12} className="aboutus_card-col">
                    <div className="aboutus-card-main-container">
                      <Col>
                        <Row className="aboutus_card-row">
                          <div>
                            <Search color="#FFFF" size="80px" />
                          </div>
                        </Row>
                        <Row className="aboutus_card-row">
                          <div className="aboutus-card-title">{'Asset Transparency'}</div>
                        </Row>
                        <Row>
                          <div className="aboutus-card-text">
                            {
                              'Offers a transparent and immutable record of all registered eco-assets, enhancing trust.'
                            }
                          </div>
                        </Row>
                      </Col>
                    </div>
                  </Col>
                  <Col xxl={6} xl={6} md={12} className="aboutus_card-col">
                    <div className="aboutus-card-main-container">
                      <Col>
                        <Row className="aboutus_card-row">
                          <div>
                            <ListTask color="#FFFF" size="80px" />
                          </div>
                        </Row>
                        <Row className="aboutus_card-row">
                          <div className="aboutus-card-title">{'Simplified Auditing'}</div>
                        </Row>
                        <Row>
                          <div className="aboutus-card-text">
                            {
                              'Streamlines the auditing process with easy access to verifiable asset data.'
                            }
                          </div>
                        </Row>
                      </Col>
                    </div>
                  </Col>
                  <Col xxl={6} xl={6} md={12} className="aboutus_card-col">
                    <div className="aboutus-card-main-container">
                      <Col>
                        <Row className="aboutus_card-row">
                          <div>
                            <GraphUpArrow className="aboutusicon" color="#FFFF" size="80px" />
                          </div>
                        </Row>
                        <Row className="aboutus_card-row">
                          <div className="aboutus-card-title">{'Market Access'}</div>
                        </Row>
                        <Row>
                          <div className="aboutus-card-text">
                            {
                              'Facilitates the buying, selling, and trading of registered eco-assets through a secure platform.'
                            }
                          </div>
                        </Row>
                      </Col>
                    </div>
                  </Col>
                  <Col xxl={6} xl={6} md={12} className="aboutus_card-col">
                    <div className="aboutus-card-main-container">
                      <Col>
                        <Row className="aboutus_card-row">
                          <div>
                            <Flower1 className="aboutusicon" color="#FFFF" size="80px" />
                          </div>
                        </Row>
                        <Row className="aboutus_card-row">
                          <div className="aboutus-card-title">{'Environmental Impact'}</div>
                        </Row>
                        <Row>
                          <div className="aboutus-card-text">
                            {
                              'Encourages sustainable practices by making eco-asset registration straightforward and accessible.'
                            }
                          </div>
                        </Row>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row className="homepagebody_aboutusline1">
                {
                  "SIGNET stands as a testament to Sage Intel's commitment to leveraging technology for creating more secure, transparent, and efficient systems. With its advanced features, adherence to industry standards, and interoperability with other platforms, SIGNET is poised to become the gold standard in blockchain-based asset verification."
                }
              </Row>
              <Row className="homepagebody_aboutuslines">
                {
                  "Sage Intel's service offerings are at the forefront of revolutionizing digital transformation, providing unparalleled solutions that empower organizations to navigate complexity, make data-driven decisions, and secure their digital ecosystems. By seamlessly integrating analytics, security, and operational excellence, we're not just changing the game, we're setting new standards for what's possible in the digital age."
                }
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <LayoutFooter />
    </div>
  );
};

export default Homepage;
